import { Controller } from 'stimulus'
import { addEventListener } from 'utils'

export default class extends Controller {
  static values = {
    activeClass: String,
    drawerSelector: String
  }

  connect () {
    this.drawer = document.querySelector(this.drawerSelectorValue)
    this.removeShowListener = addEventListener(this.drawer, 'show.bs.offcanvas', this.activate.bind(this))
    this.removeHideListener = addEventListener(this.drawer, 'hide.bs.offcanvas', this.deactivate.bind(this))
  }

  disconnect () {
    this.removeShowListener()
    this.removeHideListener()
  }

  activate () {
    this.element.classList.add(this.activeClassValue)
  }

  deactivate () {
    this.element.classList.remove(this.activeClassValue)
  }
}
