import { Controller } from 'stimulus'
import { loadScript } from '@paypal/paypal-js'

const paypalConfig = {
  'client-id': window.env.PAYPAL_CLIENT_ID,
  currency: 'EUR',
  locale: 'de_DE',
  'disable-funding': [
    'card',
    'credit',
    'paylater',
    'bancontact',
    'blik',
    'eps',
    'giropay',
    'ideal',
    'mercadopago',
    'mybank',
    'p24',
    'sepa',
    'sofort',
    'venmo'
  ]
}

export default class extends Controller {
  static targets = ['firstName', 'zipCode', 'paypalButtonsContainer', 'form', 'submitButton', 'errors']

  static values = {
    paypalCreateUrl: String,
    paypalWebhookUrl: String
  }

  csrfToken
  purchaseToken

  connect () {
    this.csrfToken = document.querySelector('[name=\'csrf-token\']').content
    if (this.paypalAllowed) { this.initializePaypal() }
  }

  disconnect () {
    if (this.paypalAllowed) { this.destroyPaypal() }
  }

  get paypalAllowed () {
    return this.hasPaypalButtonsContainerTarget
  }

  submit () {
    const itemsAttributes = JSON.parse(this.data.get('datalayer-ecommerce-items'))

    dataLayer.push({
      event: 'ce_checkout',
      ecommerce: {
        checkout: {
          actionField: { step: 1, option: 'Stripe' },
          products: itemsAttributes
        }
      }
    })

    const variables = JSON.parse(this.data.get('datalayer-buyer-to-payment-click'))
    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      ...variables,
      firstName: this.firstNameTarget.value,
      zipCode: this.zipCodeTarget.value,
      event: 'buyer-to-payment-click',
      ecommerce: {
        value: variables.value,
        currency: 'EUR'
      }
    })
  }

  initializePaypal () {
    loadScript(paypalConfig).then(this.renderPaypalButton.bind(this))

    const submitButtonWidth = this.submitButtonTarget.clientWidth
    this.paypalButtonsContainerTarget.style.width = `${submitButtonWidth}px`
  }

  destroyPaypal () {
    this.paypalButtonsContainerTarget.replaceChildren()
  }

  renderPaypalButton () {
    paypal.Buttons({
      locale: 'de_DE',
      style: {
        layout: 'vertical',
        color: 'gold',
        shape: 'pill',
        size: 'responsive'
      },
      createOrder: this.paypalCreateOrder.bind(this),
      onApprove: this.paypalOnApprove.bind(this)
    }).render(this.paypalButtonsContainerTarget)
  }

  paypalCreateOrder (data, actions) {
    const paymentAttributes = JSON.parse(this.element.getAttribute('data-purchase-datalayer-paypal-info-attributes'))
    const shippingAttributes = JSON.parse(this.element.getAttribute('data-purchase-datalayer-shipping-info-attributes'))

    dataLayer.push(paymentAttributes)
    dataLayer.push(shippingAttributes)

    const formData = new FormData(this.formTarget)
    formData.set('purchase[payment_provider]', 'paypal')

    return fetch(this.paypalCreateUrlValue, {
      method: 'post',
      headers: {
        'X-CSRF-Token': this.csrfToken
      },
      body: formData
    }).then(
      response => response.json()
    ).then(data => {
      if (data.token) {
        this.purchaseToken = data.token
        return data.order_id
      } else {
        const errorContainer = this.errorsTarget
        errorContainer.innerHTML = data.errors
        errorContainer.classList.remove('d-none')
        errorContainer.scrollIntoView()
      }
    })
  }

  paypalOnApprove (data, actions) {
    return fetch(this.paypalWebhookUrlValue, {
      method: 'post',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: this.purchaseToken,
        payload: data
      })
    }).then(function (response) {
      return response.json()
    }).then(function (data) {
      window.location.href = data.success_url
    })
  }
}
