import { Controller } from 'stimulus'
import algoliasearch from 'algoliasearch'
import { autocomplete } from '@algolia/autocomplete-js'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'
import { html } from 'htm/preact'
import { stringify } from 'utils/qs'

const APP_ID = window.env.ALGOLIA_APP_ID
const API_KEY = window.env.ALGOLIA_API_KEY
const INDEX_NAME = window.env.ALGOLIA_INDEX_NAME
const SUGGESTIONS_INDEX_NAME = window.env.ALGOLIA_SUGGESTIONS_INDEX_NAME

export default class extends Controller {
  static values = {
    placeholder: String,
    cancelButton: String
  }

  connect () {
    this.autocompleteInstance = this.initializeAutocomplete()
  }

  disconnect () {
    this.autocompleteInstance.destroy()
  }

  initializeAutocomplete () {
    const searchClient = algoliasearch(APP_ID, API_KEY, {})
    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: SUGGESTIONS_INDEX_NAME,
      categoryAttribute: [
        INDEX_NAME,
        'facets',
        'exact_matches',
        'subcategory'
      ],
      categoriesPerItem: 1,
      getSearchParams () {
        return {
          hitsPerPage: 5
        }
      },
      transformSource ({ source }) {
        const itemQuery = (item) => {
          return item.__autocomplete_qsCategory ? `${item.query}+${item.__autocomplete_qsCategory}` : item.query
        }
        return {
          ...source,
          getItemUrl ({ item }) {
            return `/search?query=${itemQuery(item)}`
          },
          templates: {
            item (params) {
              const { item, components } = params
              return html`<a class="aa-ItemLinkCustom" href=${`/search?query=${itemQuery(item)}`}>
                            <div class="aa-ItemWrapper">
                              <div class="aa-ItemContent">
                                <div class="aa-ItemIcon aa-ItemIcon--noBorder">
                                  <svg
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                    fill="currentColor"
                                  >
                                    <path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z" />
                                  </svg>
                                </div>
                                <div class="aa-ItemContentTitle">
                                  ${components.ReverseHighlight({ hit: item, attribute: 'query' })}
                                </div>

                                ${item.__autocomplete_qsCategory && (html`<div class="aa-ItemContentSubtitle aa-ItemContentSubtitle--standalone"> - in<span> ${item.__autocomplete_qsCategory} </span></div>`)}
                              </div>
                            </div>
                          </div>`
            }
          }
        }
      }
    })

    return autocomplete({
      container: this.element,
      placeholder: this.placeholderValue,
      plugins: [querySuggestionsPlugin],
      debug: process.env.NODE_ENV === 'development',
      detachedMediaQuery: '(max-width: 768px)',
      translations: {
        detachedCancelButtonText: this.cancelButtonValue
      },
      openOnFocus: true,
      initialState: {
        query: new URL(window.location).searchParams.get('query') || ''
      },
      onSubmit: (item, event) => {
        const queryString = stringify({ query: item.state.query })
        const path = `${window.location.origin}/search?${queryString}`
        Turbolinks.visit(path)
      }
    })
  }
}
